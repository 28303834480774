<template>
    <div class="main-container">
      <h5 class="pageTitle">비행 목적</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="purpose">비행목적</label>
          <input
            v-model="form.purpose"
            type="text"
            class="input-box"
            placeholder="비행목적을 입력하세요"
          />
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerPurpose">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "FlightPurposeCreate",
    data() {
      return {
        form: {
          purpose: "",
        },
      };
    },
  
    methods: {
      async registerPurpose() {
        try {
          const token = this.getToken();
          const formData = {
            purpose: this.form.purpose, // 백엔드와 필드명 일치
          };
  
          const response = await axios.post("/master/flight-purpose", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.status === 201 || response.status === 200) {
            Swal.fire("성공", "비행목적이 성공적으로 등록되었습니다.", "success");
            this.resetForm();
            this.goToList();
          }
        } catch (error) {
          Swal.fire("오류", "등록 중 문제가 발생했습니다.", "error");
          console.error("비행목적 등록 실패:", error);
        }
      },
  
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },
  
      resetForm() {
        this.form.purpose = "";
      },
  
      goToList() {
        this.$router.push("/master-flightPurposeRead");
      },
    },
  };
  </script>
  
 <style scoped>
 /* 기존 스타일 유지 */
 .main-container {
   padding: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 
 .pageTitle {
   font-weight: bold;
   margin-bottom: 40px;
   text-align: left;
   width: 100%;
 }
 
 .form-container {
   display: flex;
   flex-direction: column;
   gap: 15px;
   width: 70%;
   max-width: 800px;
 }
 
 .form-group {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width: 100%;
 }
 
 .input-box, .select-box {
   flex: 1;
   padding: 8px;
   margin-right: 40px;
   border: 1px solid rgba(130, 128, 128, 0.26);
   border-radius: 3px;
   max-width: 300px;
 }
 .input-select {
 width: 200px;
 padding: 8px;
 border: 1px solid #ccc;
 border-radius: 4px;
}
 .checkbox {
   margin-top: 10px;
 }
 
 .button-container {
   display: flex;
   gap: 15px;
   justify-content: flex-start;
 }
 
 .button-list {
   background-color: #ff9800;
   color: white;
   padding: 10px 20px;
   border: none;
   border-radius: 3px;
   cursor: pointer;
 }
 
 .button-register {
   background-color: #4caf50;
   color: white;
   padding: 10px 20px;
   border: none;
   border-radius: 3px;
   cursor: pointer;
   
 }
 </style>
   